<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <div v-if="editDialog" class="edit-company">
        <h5>Edit company</h5>
        <EditCompany
          :countries="countries"
          :companiesEdit="companiesEdit"
          :btnCancel="btnCancel"
          :itemId="itemId"
          @cancelEdit="cancelEdit"
          @getCompanies="getCompanies"
        />
      </div>

      <div v-if="companies.length" class="my-company">
        <h5>All companies</h5>
        <DataTable
          :value="companies"
          dataKey="id"
          v-model:companies="companies"
          responsiveLayout="scroll"
        >
          <Column
            header="COMPANY"
            field="nameRus"
            style="word-break: break-all"
            :sortable="true"
          >
          </Column>
          <Column
            header="COUNTRY"
            field="country.name"
            style="word-break: break-all"
          >
          </Column>
          <Column header="URL" field="url" style="word-break: break-all">
          </Column>
          <Column header="VERIFIED" field="isVerified"></Column>

          <Column field="isVerifyRequest" header="REQUEST" :sortable="true">
            <template #body="slotProps">
              <span
                class="bange"
                :style="{
                  background: slotProps.data.isVerifyRequest
                    ? '#0fa30f'
                    : '#EF4444',
                }"
                >{{ slotProps.data.isVerifyRequest }}</span
              >
            </template>
          </Column>

          <Column
            class="bg-white border-none flex flex-nowrap justify-content-end"
          >
            <template style="width: 130px" #body="slot">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning m-1"
                @click="edit(slot.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger m-1"
                @click="del(slot.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-else class="no-company">
        <p>Not any companies yet</p>
      </div>
    </div>
    <!-- Dialogs -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
    >
      <div class="confirmation-content">
        <p class="text-center p-error" v-if="itemEdit">
          Are you sure you want to delete?
        </p>
        <p class="text-center">
          <b>{{ itemEdit.nameRus }}</b>
        </p>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companies: [],
      countries: [],
      companiesEdit: [],
      btnCancel: false,
      userId: this.$auth.$vm.state.data.id, // В целом не нужно
      editDialog: false, // открывает диалог edit
      deleteDialog: false, // открывает диалог delete
      itemEdit: '',
      itemId: '',
    }
  },
  async mounted() {
    this.getCountries()
    this.getCompanies()
    console.log('User', this.$auth.check('user'))
  },
  methods: {
    async getCountries() {
      const resp = await this.axios.get('api/countries?page=1&itemsPerPage=30&pagination=false')
      this.countries = resp.data
    },
    async getCompanies() {
      this.btnCancel = false
      const resp = await this.axios.get('api/companies')
      this.companies = resp.data
      console.log('companies-m', resp.data)
    },
    edit(value) {
      this.btnCancel = true
      this.companiesEdit = value
      this.itemId = value.id
      this.editDialog = true
    },
    cancelEdit() {
      this.btnCancel = false
      this.editDialog = false
      this.getCompanies()
    },
    del(value) {
      console.log(value) // этот объект
      this.itemEdit = value
      this.deleteDialog = true
    },
    async deleteConfirm() {
      const id = this.itemEdit.id
      const headers = {
        accept: '*/*',
      }
      try {
        const resp = await this.axios.delete(`api/companies/${id}`, headers)
        this.deleteDialog = false
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Company deleted',
          group: 'info',
          life: 3000,
        })
        console.log('resp', resp)
        this.getCompanies()
      } catch (e) {
        console.log('err', e)
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Company not delete',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
}
</script>

<style scoped>
.bange {
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
}
</style>
